import axios from "@/axios";
import { mutations } from "./store";

const sendOtpForLogin = async (payload) => {
    try {
        const url = `user-auth/password-login`;
        const { data } = await axios.post(url, payload);
        const {email} = payload
        const userName = email.split('@')[0]
        mutations.setUserName(userName)
        mutations.setUserEmail(email)
        return data;  
    } catch (error) {
        console.log(error);
        return error
    }
};

const verifyOtp = async (payload) => {
    try {
        const url = `user-auth/verify-otp`;
        const { data } = await axios.post(url, payload);
        return data;  
    } catch (error) {
        console.log(error);
        return error
    }
};

const resendOtp = async (requestId) => {
    try {
        const url = `user-auth/resend-otp?request_id=${requestId}`;
        const { data } = await axios.post(url);
        return data;  
    } catch (error) {
        console.log(error);
        return error
    }
};

const setTempPass = async (email) => {
    try {
        const emailFromPayload = email.email
        const url = `user-auth/set-temp-password?email=${email}`;
        const { data } = await axios.post(url);
        const userName = email.split('@')[0]
        mutations.setUserName(userName)
        mutations.setUserEmail(emailFromPayload)
        return data;  
    } catch (error) {
        console.log(error);
        return error
    }
};

const verifyTempPass = async (payload) => {
    try {
        const url = `user-auth/verify-temp-password`;
        const { data } = await axios.post(url, payload);
        return data;  
    } catch (error) {
        console.log(error);
        return error
    }
};



const setNewPass = async (payload) => {
    try {
        const url = `user-auth/set-password`;
        const { data } = await axios.post(url, payload);
        return data;  
    } catch (error) {
        console.log(error);
        return error
    }
};

export {
    sendOtpForLogin,
    verifyOtp,
    resendOtp,
    setTempPass,
    verifyTempPass,
    setNewPass
}
