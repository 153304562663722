<template>
    <div class="flex h-full">
        <div class="loginImage-cnt w-1/2">
            <img :src="LoginImg" class="w-full">
        </div>
        <div class="w-1/2 form-cnt h-full">
            <form @submit.prevent="submitForm" class="pr-10">
                <div class="flex flex-col gap-8 pr-20">
                    <div>
                        <!-- <Logo1/> -->
                        <img :src="Logo1" class="logo">
                    </div>
                    <div>
                        <p class="block mb-2 text-black text-2xl font-semibold">{{ currentStep===1 ? "Verify your Email ID" : "Enter Temporary Password" }}</p>
                        <p v-if="currentStep===2" class="block mb-2 text-black text-sm">Hi {{ activeUserName}}! We have shared a temporary Password to this email {{ activeUserEmail }}</p>
                    </div>
                    <div>
                        <div v-if="currentStep===1" class="mb-8">
                            <label for="Email" class="block mb-2 text-sm font-noraml text-gray-900 dark:text-white">Email</label>
                            <div class="flex items-center relative">
                                <input type="text" id="Email" v-model="formData.email" class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-12" :class=" ( showError && !isEmailValid )  ? 'border-red-600' : 'border-gray-300'" placeholder="Enter Email" >
                            </div>
                            <span v-if="showError && !isEmailValid" class="text-red-600 text-xs">Invalid Email</span>
                        </div>
                        <div class="my-8" v-else>
                            <label for="Temparary_password" class="block mb-2 text-sm font-noraml text-gray-900 dark:text-white">Temparary Password</label>
                            <div class="flex items-center relative">
                                <input type="password" ref="passRef" id="Temparary_password" v-model="formData.password" class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-12" placeholder="Enter Temarary Password" @blur="handleInputBlur">
                                <EyeClosed class="absolute right-4 cursor-pointer"  @click="togglePass('confPassRef')" v-if="isPassVisible"/>
                                <Eye class="absolute right-4 cursor-pointer " @click="togglePass('confPassRef')" v-else/>
                            </div>
                            <span v-if="showError && !isPassValid && formData.password" class="text-red-600 text-xs">Length should be greater than 11</span>
                        </div>
                       
                    </div>
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full h-12 cursor-pointer" :class="{'btnDisable':!isFormFilled || disabled}" type="submit" :disabled="!isFormFilled || disabled">
                        <loader class="mr-5" v-if="disabled"/>
                           {{ !disabled ? 'Continue' : '' }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    // import  LoginImg from "@/assets/svg/loginScreen.svg";
    import  Eye from "@/assets/svg/eye.svg";
    import  EyeClosed from "@/assets/svg/closeeye.svg";
    import { LoginImg, Logo1 } from "@/plugins/common.js";
    import {setTempPass, verifyTempPass} from "./services"
    import sections from "./store";
    import Loader from "@shared/loader";

    export default {
    components: {
        Eye,
        Loader,
        EyeClosed
    },
    data(){
        return {
            LoginImg,
            Logo1,
            disabled: false,
            isPassVisible: false,
            formData: {
                email: '',
                password: ''
            },
            requestId:null,
            currentStep: 1,
            showError: false
        }
    },
    methods: {
        async submitForm() {
            try {
                this.disabled = true;
                this.showError = true;
                if(this.currentStep==1 && !this.isEmailValid) return 
                if (this.currentStep== 1) {
                    const email = this.formData.email
                    const data = await setTempPass(email)
                    if (data.status==false) {
                        this.disabled = false
                        this.$toast.error(data.message || 'Something went wrong!')
                        return
                    }
                    this.requestId = data.data.request_id
                    this.disabled = false
                    this.nextStep()
                    return

                }
                if (this.currentStep==2 && this.isPassValid){
                    const password = this.formData.password
                    const payload ={
                        "request_id": this.requestId,
                        "password": password
                    }
                    const data = await verifyTempPass(payload)
                    this.disabled = false
                    if (data.status == false) {
                        this.$toast.error(data.message || 'Something went wrong!')
                        return
                    }
                    this.moveToPath()
                    return
                }
            } catch (error) {
                this.disabled = false
                this.$toast.error('Something went wrong!')
            }
        },
        togglePass(){
                this.$refs.passRef.type = this.$refs.passRef.type==='text' ? 'password' : 'text';
                this.isPassVisible = !this.isPassVisible;
            },
        handleInputBlur(){
            this.$refs.passRef.type = 'password';
        },
        nextStep(){
            this.showError = false;
            this.currentStep++;
        },
        moveToPath(){
            // this.$router.push("update-password")
            const params = this.$route.query ;
            params.request_id = this.requestId ;
            params.set_type = "Forgot"
            this.$router.push({ 
                path: 'update-password', 
                query: params
            })
        },
        isValidEmail(email){
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            return emailRegex.test(email);
        }
  },
  computed: {
    isFormFilled(){
        return this.currentStep===1 ? this.formData?.email : this.formData?.password
    },
    isEmailValid(){
        return this.isValidEmail(this.formData.email)
    },
    activeUserEmail(){
        return sections.user_email;
    },
    activeUserName(){
        return sections.user_name;
    },
    isPassValid(){
        return this.formData?.password.length > 11 
    }
  }
}
</script>
<style scoped>
    div{
        /* border: 1px solid red;   */
    }

    .loginImage-cnt {
        width: 50%; 
        height: 100vh; 
        overflow: hidden; 
        display: flex;
    }
    .form-cnt{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 6%;
    }
    .logo{
        width: 215px;
        height: 72px;
    }
    .btnDisable{
        background-color: #DDD;
        color: #858585;
    }
    .rounded{
        border-radius: 10px;
    }
    input{
        font-size: 16px;
    }

</style>