<template>
    <div class="flex h-full">
        <div class="loginImage-cnt w-1/2">
            <img :src="LoginImg" class="w-full">
        </div>
        <div class="w-1/2 form-cnt h-full">
            <form @submit.prevent="submitForm" class="pr-10">
                <div class="flex flex-col gap-8 pr-20">
                    <div>
                        <!-- <Logo1/> -->
                        <img :src="Logo1" class="logo">
                    </div>
                    <div>
                        <p class="block mb-2 text-black text-2xl font-semibold">Login to your account</p>
                        <p class="block mb-2 text-black text-sm">Hi {{ personalData.username }}! Please enter a New Password</p>
                    </div>
                    <div>
                        <div class="mb-8">
                            <label for="Email" class="block mb-2 text-sm font-noraml text-gray-900 dark:text-white">New Password</label>
                            <!-- <input type="text" id="Email" v-model="formData.email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-12" placeholder="Enter Email" > -->
                            <!-- <span v-if="$v.formData.email.$dirty && !$v.formData.email.required">Name is required.</span> -->
                            <div class="flex items-center relative">
                                <input type="password" ref="passRef" id="password" v-model="formData.password" class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-12" :class="showError && !isPassValid ? 'border-red-600' : 'border-gray-300'" placeholder="Enter Password" @blur="handleInputBlur">
                                <Eye class="absolute right-4 cursor-pointer "  @click="togglePass('passRef')" />
                            </div>
                            <span v-if="showError && !isPassValid && formData.password" class="text-red-600 text-xs">Length should be greater than 11</span>
                        </div>
                        <div class="my-8">
                            <label for="Temparary_password" class="block mb-2 text-sm font-noraml text-gray-900 dark:text-white">Confirm Password</label>
                            <div class="flex items-center relative">
                                <input type="password" ref="confPassRef" id="confPassword" v-model="formData.confPassword" class="bg-gray-50 border text-gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-12" :class="showError && isPassValid ? 'border-red-600' : 'border-gray-300'" placeholder="Re Enter Password" @blur="handleInputBlur" @change="handleChange">
                                <EyeClosed class="absolute right-4 cursor-pointer"  @click="togglePass('confPassRef')" v-if="isPassVisible"/>
                                <Eye class="absolute right-4 cursor-pointer " @click="togglePass('confPassRef')" v-else/>
                            </div>
                            <span v-if="showError && isPassValid" class="text-red-600 text-xs">Password doesn't match</span>
                        </div>
                       
                    </div>
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full h-12 cursor-pointer" :class="{'btnDisable':!isFormFilled || disabled}" type="submit" :disabled="!isFormFilled || disabled">
                        <loader class="mr-5" v-if="disabled"/>
                           {{ !disabled ? 'Continue' : '' }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    // import  LoginImg from "@/assets/svg/loginScreen.svg";
    import  Eye from "@/assets/svg/eye.svg";
    import  EyeClosed from "@/assets/svg/closeeye.svg";
    import { LoginImg, Logo1 } from "@/plugins/common.js";
    import {setNewPass} from "./services"
    import Loader from "@shared/loader";

    export default {
    components: {
        Eye,
        Loader,
        EyeClosed
    },
    data(){
        return {
            LoginImg,
            Logo1,
            disabled: false,
            isPassVisible: false,
            formData: {
                password: '',
                confPassword: '',
            },
            personalData:{
                username: 'James',
            },
            showError: false,
        }
    },
    methods: {
        submitForm() {
            this.handleValidation()
        },
        togglePass(ref){
                this.$refs[ref].type = this.$refs[ref].type==='text' ? 'password' : 'text';
                this.isPassVisible = !this.isPassVisible;
        },
        handleInputBlur(){
            // this.$refs.passRef.type = 'password';
        },
        moveToPath(){
            this.$router.push("/new-login")
        },
        async handleValidation(){
            try {
                this.disabled = true
                if(this.isPassValid && this.formData?.password === this.formData?.confPassword ) {
                    const requestId = this.$route.query.request_id
                    const setType  = this.$route.query.set_type ? this.$route.query.set_type : "New"
                    const payload = {
                        "request_id": requestId,
                        "password": this.formData.password,
                        "set_type": setType
                    }
                    const data = await setNewPass(payload)
                    this.disabled = false
                    if( data.status == false) {
                        this.$toast.error(data.message || 'Something went wrong!')
                        return
                    }
                    this.$toast.success('Password updated successfully!')
                    this.moveToPath();
                }
                else this.showError = true;
                this.disabled = false
            } catch (error) {
                this.disabled = false
                this.$toast.error('Something went wrong!')
            }
 

        },
        handleChange(){ 
            this.showError = false;
        },
  },
  computed: {
    isFormFilled(){
        return this.formData?.password && this.formData?.confPassword
    },
    isPassValid(){
        return this.formData?.password.length > 11 
    }
  }
}
</script>
<style scoped>
    div{
        /* border: 1px solid red;   */
    }

    .loginImage-cnt {
        width: 50%; 
        height: 100vh; 
        overflow: hidden; 
        display: flex;
    }
    .form-cnt{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 6%;
    }
    .logo{
        width: 215px;
        height: 72px;
    }
    .btnDisable{
        background-color: #DDD;
        color: #858585;
    }
    .rounded{
        border-radius: 10px;
    }
    input{
        font-size: 16px;
    }

</style>